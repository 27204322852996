import { Divider, Flex, Text } from "@chakra-ui/react";
import { PermissionEnum, useGetClientsForFolderQuery } from "generated/graphql";
import { useAuth } from "hooks/useAuth";
import { SidebarClientFolder } from "./SidebarClientFolder";
import { Link } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";

export const SidebarClientList = () => {
  const { data: clients } = useGetClientsForFolderQuery();
  const { managedClients, hasPermission } = useAuth();

  const ownClients = clients?.clients.filter((client) =>
    managedClients.find((c) => c.id === client.id)
  );

  const otherClients = clients?.clients.filter(
    (client) => !managedClients.find((c) => c.id === client.id)
  );

  return (
    <>
      {ownClients?.map((client) => (
        <SidebarClientFolder
          key={client.id}
          text={client.name}
          logoUrl={client.logo}
          fontSize="sm"
          clientId={client.id}
          isArchived={client.status === "ARCHIVED"}
        />
      ))}
      {(otherClients?.length ?? 0) > 0 && (ownClients?.length ?? 0) > 0 && (
        <Flex justify="center">
          <Divider w="70%" />
        </Flex>
      )}
      {otherClients?.map((client) => (
        <SidebarClientFolder
          key={client.id}
          text={client.name}
          logoUrl={client.logo}
          fontSize="sm"
          clientId={client.id}
          isArchived={client.status === "ARCHIVED"}
        />
      ))}
      {clients?.clients.length === 0 && (
        <Flex my="3">
          {hasPermission(PermissionEnum.CanAddClients) ? (
            <Text
              _hover={{ color: "gray.400" }}
              transition="color 0.2s"
              fontSize="sm"
              color="gray.500"
              textAlign="center"
            >
              <Link to="/clients">
                <AddIcon mr="2" /> Add a client
              </Link>
            </Text>
          ) : (
            <Text fontSize="sm" color="gray.500" textAlign="center">
              No clients
            </Text>
          )}
        </Flex>
      )}
    </>
  );
};
